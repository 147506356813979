export class LandVillage {
    id:number;
    LandVillage_code:string;
    LandVillage:string;
    total_acres:string;
    distance:number;
    plot_no:string;
    plot_type:string;
    soil:string;
    plot_coordinates:string;
    pl_rt_date:string;
    village:number;//village
    survey_no:string;
    land_type:string;
    village_name: string;
    mapCoordinates: [];
    coOrdinates: any[];
    polygon_id:string;
    isPolygonUpdated:boolean;
    first_name:string;
    last_name:string;
    middle_name:string;
    farmer:string;
}
