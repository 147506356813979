import { Component, OnInit } from '@angular/core';
import { navItems } from '../../_nav';
import { slideInAnimation } from '../../animations';
// import { fadeAnimation } from '../../animations';
import { RouterOutlet, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/Auth/auth.service';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../environments/environment';
export class MenuItem {
  label:string;
  url:string;
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  animations: [slideInAnimation],
  styles: [`
  :host ::ng-deep .custom-spinner-template {
      height: 100px !important;
      top: calc( 50% - ( 59px / 2) );
      right: calc( 50% - ( 158px / 2) ); /* where ... is the element's width */
      background-color: transparent !important;
      opacity : 1
  }
  :host ::ng-deep .spinner{
    background-color: #524E50 !important;
  }
  `]
})
export class DefaultLayoutComponent implements OnInit {

  public sidebarMinimized = false;
  public finalNavItems;
  menuItems;
  static readonly ROUTE_DATA_BREADCRUMB = 'title';
  constructor(public authService: AuthService, private router : Router, private activatedRoute : ActivatedRoute) {
    navItems.forEach(nav => {
      if(nav.children){
        nav.children.forEach(navChild => {
          if (navChild.accessRoles && !this.authService.checkRoleBaseAccess(navChild.accessRoles)) {
            // navChild.class = (navChild.class) ? navChild.class + ' d-none' : 'd-none';
          }
        })
      }
      
    })
  }
  template: string = `<div class="loader-wrapper"><img class="custom-spinner-template" src="assets/img/gif/loading_leaf.gif" /></div>`;

  ngOnInit(): void {
    this.finalNavItems = navItems;
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
   
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.menuItems = this.createBreadcrumbs(this.activatedRoute.root)
        
      });
    
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs = []): MenuItem[] {
    const homeUrl = '#'+this.authService.redirectToHomePageByRole(false);
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[DefaultLayoutComponent.ROUTE_DATA_BREADCRUMB];
      if (!isNullOrUndefined(label) && label != "Home") {
        if(label == 'Dashboard'){
          breadcrumbs.push({label, homeUrl});
        }else{
          breadcrumbs.push({label, url});
        }
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  getAnimationData(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  logout() {
    this.authService.logout();
  }

  toggleNavLink(navlink){
    navlink.active = !navlink.active;
  }

  goToTrials(){
    let token = this.authService.getToken();
      let currentUrl = window.location.href;
      // ip
      if (currentUrl == `${environment.prodURLCMS}/#/base/adminGroup`) {
        window.open(`${environment.prodURLTrial}/trial-service-request?token=`+token)
      }else if (currentUrl == `${environment.devUrlCMS}/#/base/adminGroup`){
        window.open(`${environment.devUrlTrial}/trial-service-request?token=`+token)
      }else if (currentUrl == `${environment.stageUrlCMS}/#/base/adminGroup`){
        window.open(`${environment.stageUrlTrial}/trial-service-request?token=`+token)
      }else{
        window.open(`${environment.devUrlTrial}/trial-service-request?token=`+token)

      }

    // window.location.href = environment.trialsUrl; 
  }
}
