import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-preview-modal',
  templateUrl: './upload-preview-modal.component.html',
  styleUrls: ['./upload-preview-modal.component.css']
})
export class UploadPreviewModalComponent implements OnInit {
  @Input() data;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
