import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PersonService } from '../../services/Person/person.service';
import { Person } from '../../models/person';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'jhi-person-search-dialog',
  templateUrl: './person-search.component.html'
})
export class PersonSearchComponent implements OnInit {
  persons: Person[];
  title :any;
  message:any;
  hiddenButtons:any;
  hideButtons:any;
  btnCancelText :any;
  btnOkText:any;
 
  constructor(private activeModal: NgbActiveModal, private personService: PersonService, private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.spinnerService.show();
    this.personService.getPersonDetails().subscribe(resp => {
      this.spinnerService.hide();
      this.persons = resp;
    },
    error =>{
      this.spinnerService.hide();
    })
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
