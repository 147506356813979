import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/Auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LandvillageService } from '../../services/LandVillage/landvillage.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styles: [`:host ::ng-deep .custom-spinner-template {
    height: 100px !important;
    top: calc( 50% - ( 59px / 2) );
    right: calc( 50% - ( 158px / 2) ); /* where ... is the element's width */
    background-color: transparent !important;
    opacity : 1
}
:host ::ng-deep .spinner{
  background-color: #524E50 !important;
}`]
})
export class LoginComponent implements OnInit {
  loginFrom: FormGroup;
  redirectURL: string;
  template: string = `<div class="loader-wrapper"><img class="custom-spinner-template" src="assets/img/gif/loading_leaf.gif" /></div>`;
  constructor(private landVillageService :LandvillageService,private authService: AuthService, private router: Router, private spinnerService: Ng4LoadingSpinnerService,private route: ActivatedRoute) {
    this.loginFrom = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    })
  }

  public login() {
    this.spinnerService.show();
    this.authService.authenticate(this.loginFrom.value).subscribe(res => {
      console.log(res);
      this.authService.login().subscribe(resp => {
        if (resp && resp.length > 0 && resp[0].username) {
          // const user = {username: "farmer" , groups : ['Managers']};
          this.authService.setUserSession(resp[0]);
          this.authService.redirectToHomePageByRole(true);
          this.router.navigateByUrl(this.redirectURL);
          this.spinnerService.hide();
        } else {
          this.loginFrom.setErrors({ userNotFound: true });
          this.spinnerService.hide();
        }
      })
    }, err => {
      this.loginFrom.setErrors({ userNotFound: true });
      this.spinnerService.hide();
    });
  }

  ngOnInit(): void {
    if (this.authService.getUser()) {
      this.router.navigate(['/dashboard']);
    }
    this.redirectURL = this.route.snapshot.queryParams['redirectURL'] || '/';
  }

  

  getApiKeys(){
    this.landVillageService.getKeys().subscribe((data) => {
      sessionStorage.setItem('googleMap', data[0].key);
      sessionStorage.setItem('locationSearch', data[1].key);

    })
  }
}
