export const locationUrl = `${location.protocol}//${location.hostname}`;
export const environment = {
  production: true,
//  apiUrl:`${locationUrl}:8000`
  baseUrl:'https://pbsbe.piatrika.com',
  djangoReporturl:'https://pbsbe.piatrika.com',
  djangourlDev:'http://15.207.196.7.123:4200/',
  djangourl:'http://15.207.196.7:8000/static/ang/',
  //  djangoReporturl:'http://15.207.196.7:8000',
  djangoCharturl:'http://15.207.196.7:3000',
  prodURLCMS : 'https://cms.piatrika.com',
  prodURLTrial : 'https://trials.piatrika.com',
  stageUrlTrial: 'https://stage-trials.piatrika.com',
  stageUrlCMS: 'https://stage-cms.piatrika.com',
  devUrlTrial: 'https://dev-trials.piatrika.com',
  devUrlCMS: 'https://dev-cms.piatrika.com',
};
