import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goAdminGroup(){
    this.router.navigateByUrl('base/adminGroup');
  }
  goFactoryGroup(){
    this.router.navigateByUrl('base/factoryGroup');
  }
  goFieldmanGroup(){
    this.router.navigateByUrl('base/fieldmanGroup');
  }  

  // Miscelleanous
  goAction() {
    this.router.navigateByUrl('base/action');
  }
  goBank() {
    this.router.navigateByUrl('base/bank');
  }
  goCenter() {
    this.router.navigateByUrl('base/center');
  }
  goCenterquota() {
    this.router.navigateByUrl('base/centerquota');
  }
  goCertification() {
    this.router.navigateByUrl('base/certificationaggrement');
  }
  goCrop() {
    this.router.navigateByUrl('base/crop');
  }
  goCrusher() {
    this.router.navigateByUrl('base/crusher');
  }
  goCrusherInc() {
    this.router.navigateByUrl('base/crusherincoming');
  }
  goDisposal() {
    this.router.navigateByUrl('base/disposal');
  }
  goDivision() {
    this.router.navigateByUrl('base/divisionsection');
  }
  goFactory() {
    this.router.navigateByUrl('base/factory');
  }
  goFarmer() {
    this.router.navigateByUrl('base/farmer');
  }
  goFieldman() {
    this.router.navigateByUrl('base/fieldman');
  }
  goGrower() {
    this.router.navigateByUrl('base/grower');
  }
  goGuarantor() {
    this.router.navigateByUrl('base/guarantor');
  }
  goHarevsting() {
    this.router.navigateByUrl('base/harvesting');
  }
  goIncentive() {
    this.router.navigateByUrl('base/incentive');
  }
  goLandvillage() {
    this.router.navigateByUrl('base/land-village');
  }
  goLoan() {
    this.router.navigateByUrl('base/loan');
  }
  goMachinery() {
    this.router.navigateByUrl('base/machinery');
  }
  goPayment() {
    this.router.navigateByUrl('base/payment');
  }
  goPermit() {
    this.router.navigateByUrl('base/permit');
  }
  goPermitColor() {
    this.router.navigateByUrl('base/permit-color');
  }
  goPersonBank() {
    this.router.navigateByUrl('base/person-bank');
  }
  goPersonFamily() {
    this.router.navigateByUrl('base/person-family');
  }
  goPhenotype() {
    this.router.navigateByUrl('base/phenotype');
  }
  goPlant() {
    this.router.navigateByUrl('base/plant');
  }
  goPlotVisit() {
    this.router.navigateByUrl('base/plot-visit');
  }
  goSampling() {
    this.router.navigateByUrl('base/sampling');
  }
  goSeason() {
    this.router.navigateByUrl('base/season');
  }
  goSoil() {
    this.router.navigateByUrl('base/soil');
  }
  goSowing() {
    this.router.navigateByUrl('base/sowing');
  }
  goStock() {
    this.router.navigateByUrl('base/stock');
  }
  goTransfer() {
    this.router.navigateByUrl('base/transfer');
  }
  goVariety() {
    this.router.navigateByUrl('base/variety');
  }
  goVillage() {
    this.router.navigateByUrl('base/village');
  }
  goWeighment() {
    this.router.navigateByUrl('base/weighment');
  }
  goZone() {
    this.router.navigateByUrl('base/zone');
  }
}
