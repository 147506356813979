import { INavBadge, INavAttributes, INavLabel, INavLinkProps, INavWrapper } from '@coreui/angular/lib/sidebar/app-sidebar-nav';
export interface INavData {
  name?: string;
  url?: string | any[];
  href?: string;
  icon?: string;
  badge?: INavBadge;
  title?: boolean;
  children?: INavData[];
  variant?: string;
  attributes?: INavAttributes;
  divider?: boolean;
  class?: string;
  label?: INavLabel;
  wrapper?: INavWrapper;
  linkProps?: INavLinkProps;
  accessRoles?: string;
}

export const navItems: INavData[] = [
  // {
  //   name: 'Crop Management',
  //   url: '/main',
  //   icon: 'icon-home',
  //   class: "level-1",
  //   children: [
  //     {
  //       name: 'Admin group',
  //       url: '/base/adminGroup',
  //       icon: 'icon-energy',
  //       accessRoles: 'admin',
  //       class: "level-2",
  //       children: [
  //         {
  //           name: 'Factory',
  //           url: '/base/factory',
  //           icon: 'fa fa-industry'
  //         },
  //         {
  //           name: 'Division Section',
  //           url: '/base/divisionsection',
  //           icon: 'fa fa-sliders',

  //         },
  //         {
  //           name: 'Center',
  //           url: '/base/center',
  //           icon: 'icon-location-pin',

  //         },
  //         {
  //           name: 'Zone',
  //           url: '/base/zone',
  //           icon: 'fa fa-map-pin',

  //         },
  //         {
  //           name: 'Village',
  //           url: '/base/village',
  //           icon: 'fa fa-map-marker',

  //         },
  //         {
  //           name: 'Bank',
  //           url: '/base/bank',
  //           icon: 'fa fa-bank',

  //         },
  //         {
  //           name: 'Crop',
  //           url: '/base/crop',
  //           icon: 'fa fa-tree',

  //         },
  //         {
  //           name: 'Soil',
  //           url: '/base/soil',
  //           icon: 'fa fa-microchip',

  //         },
  //         {
  //           name: 'Crusher',
  //           url: '/base/crusher',
  //           icon: 'fa fa-building',

  //         },
  //         {
  //           name: 'Permit Color',
  //           url: '/base/permit-color',
  //           icon: 'icon-envelope-open',

  //         }
  //       ]
  //     },
  //     {
  //       name: 'Factory group',
  //       url: '/base/factoryGroup',
  //       icon: 'icon-energy',
  //       accessRoles: 'factory',
  //       class: "level-2",
  //       children: [
  //         {
  //           name: 'Accounts',
  //           url: '/base/accounts',
  //           icon: 'icon-energy',
  //           children: [
  //             {
  //               name: 'Guarantor',
  //               url: '/base/guarantor',
  //               icon: 'icon-user'
  //             },
  //             {
  //               name: 'Incentive',
  //               url: '/base/incentive',
  //               icon: 'fa fa-dollar'
  //             },
  //             {
  //               name: 'Loan',
  //               url: '/base/loan',
  //               icon: 'fa fa-euro'
  //             },
  //             {
  //               name: ' Person Bank',
  //               url: '/base/person-bank',
  //               icon: 'fa fa-bank'
  //             },
  //             {
  //               name: 'Payment',
  //               url: '/base/payment',
  //               icon: 'fa fa-credit-card'
  //             },
  //             {
  //               name: 'Transfer',
  //               url: '/base/transfer',
  //               icon: 'fa fa-spinner'
  //             },
  //             {
  //               name: 'Person family',
  //               url: '/base/person-family',
  //               icon: 'fa fa-users'
  //             },
  //             {
  //               name: 'Fieldman',
  //               url: '/base/fieldman',
  //               icon: 'fa fa-user'
  //             },

  //             {
  //               name: 'Plant',
  //               url: '/base/plant',
  //               icon: 'cui-dashboard'
  //             },
  //           ]
  //         },
  //         {
  //           name: 'Logistics',
  //           url: '/base/logistics',
  //           icon: 'icon-energy',
  //           children: [
  //             {
  //               name: 'Center Quota',
  //               url: '/base/centerquota',
  //               icon: 'fa fa-percent'
  //             },
  //             {
  //               name: 'Weighment',
  //               url: '/base/weighment',
  //               icon: 'fa fa-balance-scale'
  //             },
  //             {
  //               name: 'Machinery',
  //               url: '/base/machinery',
  //               icon: 'fa fa-industry'
  //             },
  //             {
  //               name: 'Permit',
  //               url: '/base/permit',
  //               icon: 'icon-envelope-letter'
  //             },
  //             {
  //               name: 'Stock',
  //               url: '/base/stock',
  //               icon: 'fa fa-bar-chart'
  //             },
  //           ]
  //         },
  //         {
  //           name: 'Inward tracking',
  //           url: '/base/accounts',
  //           icon: 'icon-energy',
  //           children: [
  //             {
  //               name: 'Crusher Incoming',
  //               url: '/base/crusherincoming',
  //               icon: 'fa fa-simplybuilt'
  //             },
  //             {
  //               name: 'Weighment',
  //               url: '/base/weighment',
  //               icon: 'fa fa-balance-scale'
  //             },
  //             {
  //               name: 'Permit',
  //               url: '/base/permit',
  //               icon: 'icon-envelope-letter'
  //             },
  //             {
  //               name: 'Sampling',
  //               url: '/base/sampling',
  //               icon: 'cui-brush'
  //             }
  //           ]
  //         },
  //       ]
  //     },

  //     {
  //       name: 'Fieldman group',
  //       url: '/base/fieldmanGroup',
  //       icon: 'icon-energy',
  //       accessRoles: 'fieldman,user',
  //       class: "level-2",
  //       children: [
  //         {
  //           name: 'Farmer',
  //           url: '/base/farmer',
  //           icon: 'fa fa-users',
  //           class: "childern"
  //         },
  //         {
  //           name: 'Land Village',
  //           url: '/base/land-village',
  //           icon: 'icon-location-pin'
  //         },
  //         {
  //           name: 'Aggrement',
  //           url: '/base/certificationaggrement',
  //           icon: 'fa fa-certificate'
  //         },
  //         {
  //           name: 'Disposal',
  //           url: '/base/disposal',
  //           icon: 'fa fa-recycle'
  //         },
  //         {
  //           name: 'Plot Visit',
  //           url: '/base/plot-visit',
  //           icon: 'cui-monitor'
  //         },
  //         {
  //           name: 'Season',
  //           url: '/base/season',
  //           icon: 'icon-calendar'
  //         },
  //         {
  //           name: 'Action',
  //           url: '/base/action',
  //           icon: 'icon-energy'
  //         },
  //         {
  //           name: 'Sowing',
  //           url: '/base/sowing',
  //           icon: 'fa fa-snowflake-o'
  //         },
  //         {
  //           name: 'Harvesting',
  //           url: '/base/harvesting',
  //           icon: 'icon-basket-loaded'
  //         },
  //         {
  //           name: 'Phenotype',
  //           url: '/base/phenotype',
  //           icon: 'fa fa-cubes'
  //         },
  //         {
  //           name: 'Sampling',
  //           url: '/base/sampling',
  //           icon: 'cui-brush'
  //         },
  //         {
  //           name: 'Variety',
  //           url: '/base/variety',
  //           icon: 'fa fa-tachometer'
  //         },
  //         {
  //           name: 'Growers',
  //           url: '/base/grower',
  //           icon: 'icon-user-female'
  //         },

  //       ]
  //     }
  //   ]
  // },
  {
    name: 'Charts',
    url: '/base',
    icon: 'fa fa-pie-chart',
    class: "level-1",
    children: [
      {
        name: 'Farmer Chart',
        // url: '/base/farmer-performance-chart',
        url: '/base/chart-farmer-performance-by-location',
        icon: 'fa fa-bar-chart'
      },
      {
        name: 'Soil Chart',
        url: '/base/soil-chart',
        icon: 'fa fa-line-chart'
      },
      {
        name: 'Farmers landholding',
        url: '/base/farmer-landholding-chart',
        icon: 'fa fa-area-chart'
      },
      {
        name: 'Operations & Application costs',
        url: '/base/opsapps-costs-chart',
        icon: 'fa fa-pie-chart'
      },
      {
        name: 'Loans & incentives',
        url: '/base/loans-subsidies-chart',
        icon: 'fa fa-bar-chart'
      },
      {
        name: 'Weather by field',
        url: '/base/field-weather-chart',
        icon: 'fa fa-area-chart'
      },
    ]
  },
  {
    name: 'Reports',
    url: '/base',
    icon: 'fa fa-fort-awesome',
    class: "level-1",
    children: [
      {
        name: 'Action Report',
        url: '/base/action-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Centre Quota Report',
        url: '/base/centre-quota-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Certification Agreement Report',
        url: '/base/certification-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Disposal Report',
        url: '/base/disposal-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Farmer Report',
        url: '/base/farmer-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Person Report',
        url: '/base/person-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Center Report',
        url: '/base/center-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'LandVillage Report',
        url: '/base/landvillage-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Fieldman Report',
        url: '/base/fieldman-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Division Section Report',
        url: '/base/division-section-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Harvesting Report',
        url: '/base/harvesting-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Incentive Report',
        url: '/base/incentive-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Loan Report',
        url: '/base/loan-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Plot Visit Report',
        url: '/base/plot-visit-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Sampling Report',
        url: '/base/sampling-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Season Report',
        url: '/base/season-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Stock Report',
        url: '/base/stock-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Transfer Report',
        url: '/base/transfer-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Village Report',
        url: '/base/village-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Variety Report',
        url: '/base/variety-report',
        icon: 'fa fa-file-text'
      },
      {
        name: 'Weighment Report',
        url: '/base/weighment-report',
        icon: 'fa fa-file-text'
      },
    ]
  }
  //,
  // {
  //   name: 'Live Charts',
  //   url: '/base',
  //   icon: 'fa fa-pie-chart',
  //   class: "level-1",
  //   children: [
  //     {
  //       name: 'Farmer Chart',
  //       url: '/base/chart-farmer-performance',
  //       icon: 'fa fa-bar-chart'
  //     }//,
  //     // {
  //     //   name: 'Soil Chart',
  //     //   url: '/base/soil-chart',
  //     //   icon: 'fa fa-line-chart'
  //     // },
  //     // {
  //     //   name: 'Farmers landholding',
  //     //   url: '/base/farmer-landholding-chart',
  //     //   icon: 'fa fa-area-chart'
  //     // },
  //     // {
  //     //   name: 'Operations & Application costs',
  //     //   url: '/base/opsapps-costs-chart',
  //     //   icon: 'fa fa-pie-chart'
  //     // },
  //     // {
  //     //   name: 'Loans, subsidies & incentives',
  //     //   url: '/base/loans-subsidies-chart',
  //     //   icon: 'fa fa-bar-chart'
  //     // },
  //     // {
  //     //   name: 'Weather by field',
  //     //   url: '/base/field-weather-chart',
  //     //   icon: 'fa fa-area-chart'
  //     // },
  //   ]
  // }
];
