import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AppConstants } from '../../app.constant';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '../../models/user';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  roles = [];
  constructor(private httpClient: HttpClient, private router: Router) {
    const userRole = JSON.parse(sessionStorage.getItem('currentUserRole'));
    if (userRole) {
      this.roles = userRole;
    }
  }

  public login() {
    return this.httpClient.get<User[]>(`${environment.baseUrl}/login/`);
  }

  public setUserSession(user) {
    this.roles = user.groups;
    sessionStorage.setItem('currentUserRole', JSON.stringify(user.groups));
    sessionStorage.setItem('currentUser', JSON.stringify(user));
  }
  public authenticate(loginModel) {
    if (AppConstants.isLoginUrlExist) {
      return this.httpClient.post(`${environment.baseUrl}/api-token-auth/`, loginModel).pipe(map(user => {
        //store user details in local storage to keep user logged in between page refreshes
        sessionStorage.setItem('token', user['token']);
        // sessionStorage.setItem('currentUserRole', JSON.stringify(user['roles']));
        // user['user_id'] = 1;
        // user['roles'] = 'admin';
        // return user;
      }));
    } else {
      return this.getDummyUserLogin(loginModel);
    }
  }

  public getUser(): any {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  public isLoggedIn() {
    return JSON.parse(sessionStorage.getItem('currentUser')) !== null ? true : false;
  }

  private getDummyUserLogin(loginModel) {
    const user = { user_id: 1, first_name: 'abcd', role: '' }
    if (loginModel.password === 'p055word') {
      switch (loginModel.username.toLowerCase()) {
        case 'fieldman':
          user.role = 'fieldman';
          break;
        case 'admin':
          user.role = 'admin';
          break;
        case 'factory':
          user.role = 'factory';
          break;
        case 'user':
          user.role = 'user';
          break;
      }
    }

    if (user.role) {
      sessionStorage.setItem('currentUser', JSON.stringify(user));
      sessionStorage.setItem('currentUserRole', user.role);
      return new Observable(subscriber => {
        subscriber.next(user);
        subscriber.complete();
      });
    } else {
      return new Observable(subscriber => {
        subscriber.error({ msg: 'not found' });
        subscriber.complete();
      });
    }
  }

  public checkRoleBaseAccess(role) {
    const userRole = sessionStorage.getItem('currentUserRole');
    if (userRole) {
      return (role.split(',').indexOf(userRole)) > -1 ? true : false;
    }
    return false;
  }

  public logout() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  public redirectToHomePageByRole(redirect) {

    let route = '/main';

    if (this.roles) {
      if (this.roles.indexOf('Fieldmen') > -1) {
        route = '/base/fieldmanGroup';
      } else if (this.roles.indexOf('Farmers') > -1) {
        route = '/base/farmerGroup';
      } else if (this.roles.indexOf('Managers') > -1) {
        route = '/base/adminGroup';
      } else if (this.roles.indexOf('Factory') > -1) {
        route = '/base/factoryGroup';
      }
      // switch (userRole.toLowerCase()) {
      //   case 'fieldman':
      //     route = '/base/fieldmanGroup';
      //     break;
      //   case 'admin':
      //     route = '/base/adminGroup';
      //     break;
      //   case 'factory':
      //     route = '/base/factoryGroup';
      //     break;
      //   case 'user':
      //     route = '/base/fieldmanGroup';
      //     break;
      // }
    }
    if (redirect) {
      this.router.navigate([route])
    } else {
      return route;
    }

  }

  getToken() {
    return sessionStorage.getItem('token')
  }

  setToken({ token }) {
    return sessionStorage.setItem('token', token);
  }

  showToggler() {
    if ((this.roles.indexOf('Factory') > -1) || (this.roles.indexOf('Managers') > -1)) {
      return 'lg'
    }
    return false
  }
}