import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/Auth/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ConfirmationDialogService } from '../shared-component/confirmation-modal/confirmation-dialog.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(public toastr: ToastrService, private authservice : AuthService, private spinnerservice : Ng4LoadingSpinnerService, private confirmationService : ConfirmationDialogService, private router : Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = this.authservice.getToken();

        if (token && request.url.indexOf('soilhealth.dac.gov.in') == -1 && request.url.indexOf('-bucket') == -1) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                // if (event instanceof HttpResponse) {
                //     console.log('event--->>>', event);
                // }
                return event;
            }),
             catchError((error: HttpErrorResponse) => {
                
                let data = {};
                data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                this.spinnerservice.hide()
                if(error.url.indexOf('api-token-auth/') == -1 && error.url.indexOf('login/') == -1){
                    if(error.status == 401){
                        this.confirmationService.confirm("Session Timeout","Your session has been timeout. Plase login again to continue.","","","lg",true,true).then(resp=>{
                            sessionStorage.clear();
                            this.router.navigate(['/login']);
                        })
                    }else{
                        if(error.message){
                            this.toastr.error(error.message);
                        }else{
                            this.toastr.error("Something went wrong!!");
                        }
                        
                    }
                    
                }
                return throwError(error);
            }));
    }
}
