export class Person {
    id: number;
    title: string;
    first_name:string;
    middle_name:string;
    last_name:string;
    user:string;
    age:string;
    gender:string;
    address:string;
    email:string;
    phone:string;
    litrary_status:string;
    village: number;
    village_name : string;
    landvillage :number;
}
