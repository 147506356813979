import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, forkJoin, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  constructor(private http: HttpClient) { }
  private piatrikaUrl = `${environment.baseUrl}`;  // URL to web api
  geoJson$ = new BehaviorSubject([]);
  getFarmerPerformance() {
    return this.http.get<any>(`${this.piatrikaUrl}/farmer-per-chart/`)    ///chart-perfomance/
    //   .pipe(map(data => {
    //     return data
    // }));
  }
  getFarmerPerformanceBySearch(searchstring) {
    return this.http.get<any>(`${this.piatrikaUrl}/farmer-per-chart/?${searchstring}`)
  }
  getFactoryAvg() {
    return this.http.get<any>(`${this.piatrikaUrl}/factory_avg_total/`)    ///chart-perfomance/
  }
  getFactoryAvgBySearch(searchstring) {
    return this.http.get<any>(`${this.piatrikaUrl}/factory_avg_total/?${searchstring}`)
  }
  getZoneAvg() {
    return this.http.get<any>(`${this.piatrikaUrl}/zone_avg_total/`)    ///chart-perfomance/
  }
  getZoneAvgBySearch(searchstring) {
    return this.http.get<any>(`${this.piatrikaUrl}/zone_avg_total/?${searchstring}`)
  }
  getVillageAvg() {
    return this.http.get<any>(`${this.piatrikaUrl}/village_avg_total/`)
  }
  getVillageAvgBySearch(searchstring) {
    return this.http.get<any>(`${this.piatrikaUrl}/village_avg_total/?${searchstring}`)
  }
  getLandvillageAvg() {
    return this.http.get<any>(`${this.piatrikaUrl}/landvillage_avg_total/`)
  }
  getLandvillageAvgBySearch(searchstring) {
    return this.http.get<any>(`${this.piatrikaUrl}/landvillage_avg_total/?${searchstring}`)
  }

  getFactoryLandholding() {
    return this.http.get<any>(`${this.piatrikaUrl}/factory_landholding/`)
  }
  getZoneLandholding() {
    return this.http.get<any>(`${this.piatrikaUrl}/zone_landholding/`)
  }
  getVillageLandholding() {
    return this.http.get<any>(`${this.piatrikaUrl}/village_landholding/`)
  }
  getFarmerLandholding() {
    // return this.http.get<any>(`${this.piatrikaUrl}/farmer-landholding/`)
    return this.http.get<any>(`${this.piatrikaUrl}/farmer_landholding/`)
  }

  getFactoryIncentive() {
    return this.http.get<any>(`${this.piatrikaUrl}/factory_incentive/`)
  }
  getZoneIncentive() {
    return this.http.get<any>(`${this.piatrikaUrl}/zone_incentive/`)
  }
  getVillageIncentive() {
    return this.http.get<any>(`${this.piatrikaUrl}/village_incentive/`)
  }
  getFarmerIncentive() {
    return this.http.get<any>(`${this.piatrikaUrl}/farmer_incentive/`)
  }
  getFactoryLoan() {
    return this.http.get<any>(`${this.piatrikaUrl}/factory_loan/`)
  }
  getZoneLoan() {
    return this.http.get<any>(`${this.piatrikaUrl}/zone_loan/`)
  }
  getVillageLoan() {
    return this.http.get<any>(`${this.piatrikaUrl}/village_loan/`)
  }
  getFarmerLoan() {
    return this.http.get<any>(`${this.piatrikaUrl}/farmer_loan/`)
  }
  getFactoryOperation() {
    return this.http.get<any>(`${this.piatrikaUrl}/factory_operations/`)
  }
  getZoneOperation() {
    return this.http.get<any>(`${this.piatrikaUrl}/zone_operations/`)
  }
  getVillageOperation() {
    return this.http.get<any>(`${this.piatrikaUrl}/village_operations/`)
  }
  getFarmerOperation() {
    return this.http.get<any>(`${this.piatrikaUrl}/farmer_operations/`)
  }
  getSoilSampling() {
    return this.http.get<any>(`${this.piatrikaUrl}/sampling_phenos/`)
  }
  getSoilSamplingBySearch(searchstring) {
    return this.http.get<any>(`${this.piatrikaUrl}/sampling_phenos/?${searchstring}`)
  }
  getSoil() {
    return this.http.get<any>(`${this.piatrikaUrl}/sampling_phenos/`)
  }

  getOperationsApplicationCosts() {
    return this.http.get<any>(`${this.piatrikaUrl}/operation-application/`)
  }

  getLoans() {
    return this.http.get<any>(`${this.piatrikaUrl}/loan-charts/`)
  }

  getIncentive() {
    return this.http.get<any>(`${this.piatrikaUrl}/incentive-charts/`)
  }

  getFieldWeather() {
    return this.http.get<any>(`${this.piatrikaUrl}/chart-weather-report/`)
  }

  getLocationAddress(latlng) {
    // Retrieve the API key from sessionStorage
    const apiKey = sessionStorage.getItem('googleMap');

    if (!apiKey) {
      throw new Error('Google Maps API key not found in sessionStorage.');
    }

    // Make the HTTP request with the dynamic API key
    return this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${apiKey}`);
  }
  getGeoJson() {
    return this.geoJson$.asObservable();
    //return this.http.get<any>(`https://cors-anywhere.herokuapp.com/https://shrikant-bucket.s3.ap-south-1.amazonaws.com/geojson8.json`);
  }

  init() {
    return new Promise<void>((resolve, reject) => {
      forkJoin([this.http.get("data/geojson8.json"), this.http.get(`${environment.baseUrl}/apikeys/`)])
        .subscribe((data: any) => {
          this.geoJson$.next(data[0]);
          const keys = data[1];
          sessionStorage.setItem('googleMap', keys[0].key);
          sessionStorage.setItem('locationSearch', keys[1].key);
          resolve();
        });
    });
  }
}

