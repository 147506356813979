import { LandVillage } from './../../models/land-village';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class LandvillageService {

  landvillage: LandVillage = new LandVillage();
  submitted = false;
  landvillages: LandVillage[];
  newLandVillageId;

  radiansPerDegree = Math.PI / 180.0;
  degreesPerRadian = 180.0 / Math.PI
  earthRadiusMeters = 6367460.0;
  metersPerDegree = 2.0 * Math.PI * this.earthRadiusMeters / 360.0;
  metersPerKm = 1000.0;
  meters2PerHectare = 10000.0;
  feetPerMeter = 3.2808399;
  feetPerMile = 5280.0;
  acresPerMile2 = 640;

  private piatrikaUrl = `${environment.baseUrl}/LandVillage`;  // URL to web api

  constructor(
    private http: HttpClient
  ) { }

  getLandVillageDetails(): Observable<LandVillage[]> {
    return this.http.get<LandVillage[]>(`${this.piatrikaUrl}/`)
  }

  getLandVillageDetailsBySearch(searchString): Observable<LandVillage[]> {
    return this.http.get<LandVillage[]>(`${this.piatrikaUrl}/?${searchString}`)
  }
  getLandVillageDetail(id: number): Observable<LandVillage> {
    const url = `${this.piatrikaUrl}/${id}/`;
    return this.http.get<LandVillage>(url);
  }

  addLandVillageDetail(LandVillageDetail: LandVillage): Observable<LandVillage> {
    return this.http.post<LandVillage>(`${this.piatrikaUrl}/`, LandVillageDetail, httpOptions);
  }
  deleteLandVillageDetail(LandVillage: LandVillage | number): Observable<LandVillage> {
    const id = typeof LandVillage === 'number' ? LandVillage : LandVillage.id;
    const url = `${this.piatrikaUrl}/${id}/`;

    return this.http.delete<LandVillage>(url, httpOptions);
  }
  updateLandVillageDetail(LandVillage: LandVillage, isPolygonUpdated): Observable<number> {
    LandVillage.isPolygonUpdated = isPolygonUpdated;
    const id = typeof LandVillage === 'number' ? LandVillage : LandVillage.id;
    const url = `${this.piatrikaUrl}/${id}/`;

    return this.http.put<number>(url, LandVillage, httpOptions);
  }

  getLandVillageAgroImages(landVillageID, polygonId): Observable<LandVillage[]> {
    return this.http.get<LandVillage[]>(`${environment.baseUrl}/getLandAgroDetails/?landVillageId=${landVillageID}&polygonId=${polygonId}`)
  }

  getLandPlanetxDetails(coordinates): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/getLandPlanetxDetails/`,{coOrdinates : coordinates});
  }

  getLandVillagesWithFilter(searchString): Observable<LandVillage[]> {
    return this.http.get<LandVillage[]>(`${environment.baseUrl}/getLandVillages/?${searchString}`)
  }

  PlanarPolygonAreaMeters2(points) {
    var a = 0.0;
    for (var i = 0; i < points.length; ++i) {
      var j = (i + 1) % points.length;
      var xi = points[i].lng * this.metersPerDegree * Math.cos(points[i].lat * this.radiansPerDegree);
      var yi = points[i].lat * this.metersPerDegree;
      var xj = points[j].lng * this.metersPerDegree * Math.cos(points[j].lat * this.radiansPerDegree);
      var yj = points[j].lat * this.metersPerDegree;

      a += xi * yj - xj * yi;
    }
    return Math.abs(a / 2.0);
  }


  Areasacre(areaMeters2) {
    var areaHectares = areaMeters2 / this.meters2PerHectare;
    var areaKm2 = areaMeters2 / this.metersPerKm / this.metersPerKm;
    var areaFeet2 = areaMeters2 * this.feetPerMeter * this.feetPerMeter;
    var areaMiles2 = areaFeet2 / this.feetPerMile / this.feetPerMile;
    var areaAcres = areaMiles2 * this.acresPerMile2;

    return areaAcres.toFixed(2);
  }


  getLandVillageDevices() {
    return this.http.get(`${environment.baseUrl}/LandvillageDevice/`)
  }
  
  getLandVillageDevice(id :any) {
    return this.http.get(`${environment.baseUrl}/LandvillageDevice/?id=`)
  }

  addLandVillageDevice(data){
    return this.http.post(`${environment.baseUrl}/LandvillageDevice/`,data);
  }

  getKeys(){
    return this.http.get(`${environment.baseUrl}/apikeys/`);
  }
}