
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './components/error/404.component';
import { P500Component } from './components/error/500.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ToastrModule } from 'ngx-toastr';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { UshellComponent } from './components/ushell/ushell.component';
// import { ActionComponent } from './views/action/action.component';
// import { BankComponent } from './views/bank/bank.component';
import { CenterComponent } from './components/center/center.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
// import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw.module';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PhenotypeComponent } from './components/phenotype/phenotype.component';
import { AtomSpinnerModule } from 'angular-epic-spinners';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ConfirmationDialogComponent } from './shared-component/confirmation-modal/confirmation-dialog.component';
import { PersonSearchComponent } from './shared-component/person-search/person-search.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ChartsService } from './services/Charts/charts.service';
import { UploadPreviewModalComponent } from './components/phenotype/upload-preview-modal/upload-preview-modal.component';


//import { ManualResourceComponent } from './views/manual-resource/manual-resource.component';
// import { EditFieldmanComponent } from './views/fieldman/edit-fieldman/edit-fieldman.component';
// import { AddPhenotypeComponent } from './views/phenotype/add-phenotype/add-phenotype.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  // return new TranslateHttpLoader(httpClient, '/static/ang/assets/i18n/', '.json'); //remove this for build
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function initializeApp(appService: ChartsService) {
  return (): Promise<any> => {
    return appService.init();
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    NgxSpinnerModule,
    NgbModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    LeafletModule.forRoot(),
    LeafletDrawModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      disableTimeOut : false,
      tapToDismiss: false,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }), 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ChartsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AtomSpinnerModule,
    AutocompleteLibModule, 
    OwlDateTimeModule, 
    OwlNativeDateTimeModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    UshellComponent,
    HomeComponent,
    ConfirmationDialogComponent,
    PersonSearchComponent,
    UploadPreviewModalComponent,
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy,

  }, 
  { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
  { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ChartsService], multi: true },
  NgbActiveModal, DecimalPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
  entryComponents:[ConfirmationDialogComponent, PersonSearchComponent, UploadPreviewModalComponent]
})
export class AppModule { }
