import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ushell',
  templateUrl: './ushell.component.html',
  styleUrls: ['./ushell.component.css']
})
export class UshellComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
